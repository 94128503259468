import React, { useRef } from 'react';
import moment from 'moment';
// Libraries
import { Swiper, SwiperSlide } from 'swiper/react';
import { Row } from 'react-bootstrap';
import { Autoplay, Pagination, Navigation } from 'swiper';
import { m } from 'framer-motion';
import { Buttons } from 'components';
import images from '../../assets/images/demo.jpg';

const RelatedSlider = (props: any) => {
  const swiperRef = useRef(null);
  const [hydrated, setHydrated] = React.useState(false);
  React.useEffect(() => {
    setHydrated(true);
  }, []);
  if (!hydrated) {
    return null;
  }
  return (
    <Row
      className={`interactivebanners-style-02 -mr-[30vw] md:mr-[-15px] sm:contents ${props.className}`}
    >
      <Swiper
        className="white-move"
        modules={[Autoplay, Pagination, Navigation]}
        {...props.carousalOption}
        ref={swiperRef}
      >
        {props?.data.map((item: any) => {
          const { id, image, title, slug, date } = item;
          return (
            <SwiperSlide key={id} className="my-swiper">
              <m.div
                className="interactivebanners-main"
                {...{
                  transition: { delay: 0.2 },
                }}
              >
                <div className="relative overflow-hidden overlay-bg">
                  <img
                    height="235"
                    width="210"
                    className="interactiveanners-img"
                    src={
                      image?.localFile?.publicURL ??
                      (image?.localFile?.publicURL || images)
                    }
                    alt="interactive banners"
                  />
                </div>
                <div className="fancy-text-content px-[55px] xs:px-[30px] xxs:px-[30px]">
                  <span className="!bg-black interactivebanners-subtitle px-[15px] py-[5px] mb-[20px] rounded-[2px] text-white text-xxs font-serif uppercase leading-4 tracking-[1px] inline-block">
                    {moment(date).format('MMM D, YYYY')}
                  </span>

                  <div className="interactivebanners-title mb-[10px] text-white text-xlg font-serif lg:text-[18px]">
                    {title}
                  </div>

                  <Buttons
                    ariaLabel="interactive button"
                    href={`/${props.parentSlug}/${slug}`}
                    title="Read More"
                    className="btn-fill text-xs tracking-[1px] font-serif font-medium rounded-none py-[12px] mr-[30px] uppercase md:mb-[15px] sm:mb-0"
                    themeColor="#42A7C3"
                    color="#fff"
                    size="xs"
                  />
                </div>
              </m.div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Row>
  );
};

export { RelatedSlider };

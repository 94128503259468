/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { Suspense, FC, useState, useEffect, useMemo } from 'react';

import { ParallaxProvider } from 'react-scroll-parallax';
import { LazyMotion, domMax, AnimatePresence } from 'framer-motion';
import { useLocation } from '@reach/router';
import retina from 'retinajs';
import { Link } from 'gatsby';
import { Col, Navbar } from 'react-bootstrap';

import { Header, HeaderNav, Menu, Footer, ScrollToTopButton } from 'components';
import { HeaderAndFooterQuery } from '../query';

import '../assets/css/global.css';
import '../assets/css/icons.css';
import '../assets/css/pages.css';
import 'sass/style.scss';

import GlobalContext from '../context/Context';

export interface LayoutProps {
  children: JSX.IntrinsicElements['div'] | any;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const data = HeaderAndFooterQuery();
  const [headerHeight, setHeaderHeight] = useState(0) as any;
  const [footerHeight, setFooterHeight] = useState(0) as any;
  const [isModalOpen, setIsModalOpen] = useState(false) as any;
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  }) as any;

  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import('../Functions/Utilities').then(module => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document?.querySelector('body')?.classList?.add('overflow-hidden');
    } else {
      document?.querySelector('body')?.classList?.remove('overflow-hidden');
    }
  }, [isModalOpen]);
  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });
  }, [location]);

  const globalProviderValue = useMemo(
    () => ({
      headerHeight,
      setHeaderHeight,
      footerHeight,
      setFooterHeight,
      isModalOpen,
      setIsModalOpen,
      customModal,
      setCustomModal,
    }),
    [
      headerHeight,
      setHeaderHeight,
      footerHeight,
      setFooterHeight,
      isModalOpen,
      setIsModalOpen,
      customModal,
      setCustomModal,
    ]
  );
  const [expanded, setExpanded] = useState(false);

  return (
    <LazyMotion features={domMax}>
      <ParallaxProvider>
        <GlobalContext.Provider value={globalProviderValue}>
          <div
            className="App"
            style={{ '--header-height': `${headerHeight}px` }}
          >
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Header topSpace={{ desktop: true }} type="reverse-scroll">
                  <HeaderNav
                    theme="light"
                    bg="white"
                    menu="light"
                    expand="lg"
                    className="px-[35px] py-[0px] lg:px-[15px] md:px-0"
                    containerClass="py-[10px] px-[10px]"
                    expanded={expanded}
                  >
                    <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
                      <Link
                        aria-label="header logo"
                        className="flex items-center"
                        to="/"
                      >
                        <Navbar.Brand className="inline-block p-0 m-0">
                          <img
                            className="default-logo"
                            width={111}
                            height={36}
                            loading="lazy"
                            src={data.strapiGlobal.logo.localFile.publicURL}
                            data-rjs={
                              data.strapiGlobal.logo.localFile.publicURL
                            }
                            alt="logo"
                          />
                          <img
                            className="alt-logo"
                            width={111}
                            height={36}
                            loading="lazy"
                            src={data.strapiGlobal.logo.localFile.publicURL}
                            data-rjs={
                              data.strapiGlobal.logo.localFile.publicURL
                            }
                            alt="logo"
                          />
                          <img
                            className="mobile-logo"
                            width={111}
                            height={36}
                            loading="lazy"
                            src={data.strapiGlobal.logo.localFile.publicURL}
                            data-rjs={
                              data.strapiGlobal.logo.localFile.publicURL
                            }
                            alt="logo"
                          />
                        </Navbar.Brand>
                      </Link>
                    </Col>
                    <div className="col-auto hidden order-last md:block px-[10px]">
                      <Navbar.Toggle
                        className="md:ml-[10px] sm:ml-0"
                        onClick={() =>
                          setExpanded(expanded ? false : 'expanded')
                        }
                      >
                        <span className="navbar-toggler-line" />
                        <span className="navbar-toggler-line" />
                        <span className="navbar-toggler-line" />
                        <span className="navbar-toggler-line" />
                      </Navbar.Toggle>
                    </div>
                    <Navbar.Collapse className="col-auto px-0 justify-end">
                      <Menu
                        menu={data.strapiGlobal.Menu}
                        setExpanded={setExpanded}
                      />
                    </Navbar.Collapse>
                    <Col className="col-auto text-right pe-0">
                      <a
                        href={`mailto:${data.strapiGlobal.email}`}
                        aria-label="email"
                        className="pl-[17px] align-middle text-[17px] xs:pl-[15px] text-xl"
                      >
                        <i className="feather-mail text-sky-600 font-bold	antialiased" />
                      </a>
                      <a
                        href={`tel:${data.strapiGlobal.phoneNumber}`}
                        aria-label="call"
                        className="pl-[17px] align-middle text-[17px] xs:pl-[15px]	text-xl"
                      >
                        <i className="feather-phone	text-sky-600	font-bold	antialiased" />
                      </a>
                      <a
                        aria-label="Chat on WhatsApp"
                        href={`https://wa.me/${data.strapiGlobal.whatsUpNumber}`}
                        className="pl-[17px] align-middle text-[17px] xs:pl-[15px] pr-[17px]	text-xl"
                      >
                        <i className="fab fa-whatsapp	text-sky-600	font-bold	antialiased" />
                      </a>
                    </Col>
                  </HeaderNav>
                </Header>
                <main
                  style={{
                    marginTop: headerHeight,
                    marginBottom: footerHeight,
                  }}
                >
                  {children}
                </main>
                <Footer theme="dark" className="text-slateblue bg-[#003B69]" />
              </Suspense>
            </AnimatePresence>
          </div>
        </GlobalContext.Provider>
      </ParallaxProvider>
    </LazyMotion>
  );
};

export default Layout;

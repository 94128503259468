/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

// Libraries
import { Col, Tab, Tabs } from 'react-bootstrap';
import { m } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Keyboard } from 'swiper';
import ReactMarkdown from 'react-markdown';

// Components
import { fadeIn } from '../../Functions/GlobalAnimations';

const Tab01 = ({ data }: any) => {
  return (
    <Tabs className="tab-style-04 font-serif mb-[3.5rem] flex bg-transparent border-b-0 font-medium text-center justify-center md:mb-[40px] sm:mb-[20px]">
      {data.map((item: any, i: number) => {
        return (
          <Tab
            className="tab-style-04"
            key={item.id}
            eventKey={i}
            title={item.title ? item.title : 'Tab Title'}
          >
            <m.div
              className="row items-center"
              {...{
                ...fadeIn,
                transition: { duration: 0.9 },
                viewport: { once: false },
              }}
            >
              <Col
                lg={{ span: item?.ImageAndVideo?.length > 0 ? 8 : 12 }}
                md={item?.ImageAndVideo?.length > 0 ? 6 : 12}
                className="md:px-[15px] sm:px-[15px] sm:text-start text-left"
              >
                {item.title && (
                  <h5 className="font-serif font-semibold text-darkgray tracking-[-0.5px] mb-[35px] sm:mb-[30px]">
                    {item.title}
                  </h5>
                )}
                {item.description && (
                  <ReactMarkdown className="mb-[45px] w-[85%] md:w-full xs:mb-[15px] text-justify">
                    {item?.description?.data?.description}
                  </ReactMarkdown>
                )}
                {item.image && (
                  <img
                    src={item?.image?.localFile?.publicURL}
                    alt="producttab"
                  />
                )}
              </Col>
              {item?.ImageAndVideo?.length > 0 && (
                <Col md={6} lg={{ span: 4 }} className="sm:mb-[40px]">
                  <Swiper
                    className="white-move swiper-pagination-light swiper-pagination-medium h-full relative swiper-navigation-04 swiper-navigation-dark travel-agency-slider"
                    slidesPerView={1}
                    loop
                    keyboard
                    navigation
                    modules={[Pagination, Keyboard, Navigation]}
                    pagination={{ clickable: true }}
                  >
                    {item.ImageAndVideo.map((element: any) => {
                      return (
                        <SwiperSlide key={element.id} className="w-full h-full">
                          {element?.image && (
                            <img
                              src={element.image.localFile.publicURL}
                              alt={item.title}
                              className="w-full h-full object-cover"
                            />
                          )}
                          {element?.videoLink && (
                            <iframe
                              src={element.videoLink}
                              title="Product video"
                              className="w-full h-full min-h-[350px] md:min-h-[450px]	 object-cover"
                            />
                          )}
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </Col>
              )}
            </m.div>
          </Tab>
        );
      })}
    </Tabs>
  );
};

const ProductTab = memo(Tab01);

export { ProductTab };

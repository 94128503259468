/* eslint-disable no-shadow */
import React, { useState, useEffect, useRef, memo } from 'react';

// Libraries
import { Link } from 'gatsby';
import { m } from 'framer-motion';
import { Buttons } from 'components';

// Components

const ProductBox = memo((props: any) => {
  const productWrapper = useRef();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    import('../../Functions/Utilities').then(module => {
      const grid = module.initializeIsotop(productWrapper.current);
      grid.on('arrangeComplete', () => setLoading(false));
    });
  }, [props.currentPage]);
  return (
    <div className="grid-wrapper">
      <ul
        ref={productWrapper}
        className={`grid-container${props.grid ? ` ${props.grid}` : ''}${
          loading ? ' loading' : ''
        }${props.className ? ` ${props.className}` : ''}`}
      >
        <li className="grid-sizer" />
        {props?.data.map((item: any, i: number) => {
          const { id, image, title, slug, productCategory } = item;
          return (
            <li
              className={`grid-item${
                item.double_col ? ' grid-item-double' : ''
              } `}
              key={id}
            >
              <m.div
                initial={{ opacity: 0 }}
                whileInView={!loading && { opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  delay: i * 0.2,
                  duration: 0.5,
                  ease: 'easeOut',
                }}
                className="product-box relative overflow-hidden w-full xs:mb-0"
              >
                <div className="product-image relative overflow-hidden">
                  <Link
                    aria-label="link"
                    to={`/${productCategory.slug}/${slug}`}
                  >
                    {image && (
                      <img
                        width={277}
                        height={359}
                        className="default-image w-full border"
                        src={image[0].localFile.publicURL}
                        alt="product-box"
                      />
                    )}
                    {image && (
                      <img
                        width={297}
                        height={379}
                        className="hover-img w-full"
                        src={
                          image[1]?.localFile?.publicURL ||
                          image[0]?.localFile?.publicURL
                        }
                        alt="product-box"
                      />
                    )}
                  </Link>
                </div>
                <div className="product-footer text-left pt-[10px]">
                  <div>
                    <Link
                      aria-label="link"
                      to={`/${productCategory.slug}/${slug}`}
                      className="text-darkgray hover:text-black font-bold inline-block mb-[10px]"
                    >
                      {title}
                    </Link>
                  </div>

                  <Buttons
                    ariaLabel="button"
                    href={`/${productCategory.slug}/${slug}`}
                    className="text-xs font-medium font-serif uppercase rounded-none bg-[#fff] hover:text-white mb-[10px] w-full"
                    color="#42A7C3"
                    size="sm"
                    themeColor="#42A7C3"
                    title="View Product"
                  />
                  <Buttons
                    ariaLabel="button"
                    href={`/${productCategory.slug}/${slug}#productForm`}
                    className="btn-fill text-xs tracking-[1px] font-serif font-medium rounded-none py-[12px] mr-[30px] uppercase w-full"
                    themeColor="#42A7C3"
                    size="sm"
                    color="#fff"
                    title="Send Enquiry"
                  />
                </div>
              </m.div>
            </li>
          );
        })}
      </ul>
    </div>
  );
});

export { ProductBox };

import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {
  PinterestShareButton,
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';

export const query = () => {
  return useStaticQuery(graphql`
    query backupQuery {
      site {
        siteMetadata {
          image
        }
      }
    }
  `);
};

const SocialShare = ({ title, image, showProductImage = false }: any) => {
  const backup = query();
  const [url, setUrl] = useState('');
  const [location, setlocation] = useState('');
  useEffect(() => {
    setUrl(window.location.href);
    setlocation(window.location.origin);
  }, []);
  const imageUrl = `${location}${
    !image ? backup.site.siteMetadata.image : image
  }`;
  return (
    <div className="social_shares_wrapper">
      <span>Share:</span>
      <ul className="social_share">
        <li>
          <FacebookShareButton url={url} quote={title}>
            <FacebookIcon round />
          </FacebookShareButton>
        </li>
        <li>
          <LinkedinShareButton url={url} title={title}>
            <LinkedinIcon round />
          </LinkedinShareButton>{' '}
        </li>
        <li>
          <TwitterShareButton url={url} title={title}>
            <TwitterIcon round />
          </TwitterShareButton>
        </li>
        <li>
          {showProductImage ? (
            <PinterestShareButton url={url} media={image} description={title}>
              <PinterestIcon round />
            </PinterestShareButton>
          ) : (
            <PinterestShareButton
              url={url}
              media={imageUrl}
              description={title}
            >
              <PinterestIcon round />
            </PinterestShareButton>
          )}
        </li>
        <li>
          <WhatsappShareButton url={url} title={title}>
            <WhatsappIcon round />
          </WhatsappShareButton>
        </li>
        <li>
          <a href={`mailto:?subject=${title}&body=${url}`}>
            <EmailIcon round />
          </a>
        </li>
      </ul>
    </div>
  );
};

export { SocialShare };

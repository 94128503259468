import { useStaticQuery, graphql } from 'gatsby';

const HeaderAndFooterQuery = () => {
  return useStaticQuery(graphql`
    query headerAndFooterQuery {
      strapiGlobal {
        logo {
          localFile {
            publicURL
          }
        }
        Menu {
          id
          link
          title
          hasSubmenu
          subMenu {
            id
            title
            link
          }
        }
        footerMenu: Footer {
          id
          title
          footerNavigation {
            id
            title
            link
          }
        }
        copyrightMessage
        faxNumber
        phoneNumber
        telephoneNumber
        email
        address {
          data {
            address
          }
        }
        whatsUpNumber
        location {
          data {
            location
          }
        }
        SocialMediaLink {
          link
          id
          title
        }
        footerLogo {
          localFile {
            publicURL
          }
        }
      }
      strapiContact {
        slug
      }
    }
  `);
};

export { HeaderAndFooterQuery };

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, { memo } from 'react';

// Libraries
import { Link } from 'gatsby';
import { Button } from 'react-bootstrap';

const Buttons = memo((props: any) => {
  const color1 = props.themeColor && props.themeColor[0];
  const color2 = props.themeColor && props.themeColor[1];
  const textcolor1 = props.color && props.color[0];
  const textcolor2 = props.color && props.color[1];
  const style = {
    '--gradient-color':
      typeof props.themeColor === 'object'
        ? `linear-gradient(45deg, ${color1}, ${color2}, ${color1})`
        : props.themeColor,
    '--brand-color':
      typeof props.themeColor === 'object'
        ? `linear-gradient(to right, ${color1}, ${color2}, ${color1})`
        : props.themeColor,
    '--text-color':
      typeof props.color === 'object'
        ? `linear-gradient(to right, ${textcolor1}, ${textcolor2}, ${textcolor1})`
        : props.color,
  };
  return props.href || props.type === 'submit' ? (
    <Button
      as={props.href ? 'a' : props.type === 'submit' ? 'button' : 'a'}
      type={props.type === 'submit' ? 'submit' : undefined}
      style={style}
      className={`border-[2px] border-solid btn-${props.size}${
        props.className ? ` ${props.className}` : ''
      }${typeof props.themeColor === 'object' ? ' btn-gradient' : ''}${
        typeof props.color === 'object' ? ' text-gradient' : ''
      }`}
      href={props.href}
      onClick={props.onClick}
      disabled={props.disabled}
      variant="secondary"
      aria-label={props.ariaLabel}
      target={props.target}
    >
      <ButtonInner {...props} />
    </Button>
  ) : (
    <Link
      style={style}
      target={props.target}
      className={`btn border-[2px] border-solid btn-${props.size}${
        props.className ? ` ${props.className}` : ''
      }${typeof props.themeColor === 'object' ? ' btn-gradient' : ''}${
        typeof props.color === 'object' ? ' text-gradient' : ''
      } `}
      to={props.to ? props.to : '#'}
      onClick={props.onClick}
      aria-label={props.ariaLabel}
    >
      <ButtonInner {...props} />
    </Link>
  );
});

const ButtonInner = (props: any) => {
  return (
    <>
      {props.icon && props.iconPosition !== 'after' && (
        <i className={`${props.icon} left-icon`} />
      )}
      {props.title}
      {props.icon && props.iconPosition === 'after' && (
        <i className={`${props.icon} right-icon`} />
      )}
      {props.theme === 'btn-link-gradient' && <span />}
    </>
  );
};

export { Buttons };

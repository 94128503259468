export const truncate = (str: string, max: number) => {
  const suffix = '...';
  return str && str.length < max
    ? str
    : str &&
        str.substr &&
        `${str.substr(
          0,
          str.substr(0, max - suffix.length).lastIndexOf(' ')
        )}${suffix}`;
};

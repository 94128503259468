/* eslint-disable react/destructuring-assignment */
import React, { memo } from 'react';

// Libraries
import { m } from 'framer-motion';

const SocialIcons = memo((props: any) => {
  return (
    <ul
      className={`social-icon flex-wrap gap-y-5 p-0 ${props.theme} ${props.size} ${props.iconColor} ${props.className}`}
    >
      {props.data.map((item: any) => {
        return (
          <m.li key={item.id} style={{ '--social-icon-color': '#fff' }}>
            <a
              href={item.link}
              aria-label="social icon"
              target="_blank"
              rel="noreferrer"
            >
              <div className={`${item.position} social-front grid`}>
                {item.title && <i className={item.title} />}
              </div>
            </a>
          </m.li>
        );
      })}
    </ul>
  );
});

export { SocialIcons };

import React from 'react';
import { m } from 'framer-motion';
import { Col, Container, Row } from 'react-bootstrap';

const PortfolioOverlay = (props: any) => {
  const portfolioWrapper = React.useRef();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const allImages = portfolioWrapper.current.querySelectorAll('img');

    Promise.all(
      Array.prototype.slice
        .call(allImages)
        .filter(img => !img.complete)
        .map(
          img =>
            new Promise(resolve => {
              img.onload = img.onerror = resolve;
            })
        )
    ).then(() => {
      import('../../Functions/Utilities').then(module => {
        const grid = module.initializeIsotop(portfolioWrapper.current);
        grid.on('arrangeComplete', () => setLoading(false));
      });
    });
  }, []);

  const style = {
    '--overlay-color': '#fff',
  };

  return (
    <div className="grid-wrapper">
      {/* Grid Start */}
      <ul
        ref={portfolioWrapper}
        className={`grid-container text-center${
          props.grid ? ` ${props.grid}` : ''
        }${props.className ? ` ${props.className}` : ''}${
          loading ? ' loading' : ''
        }`}
      >
        <li className="grid-sizer" />
        {props.data.map((item, i) => {
          return (
            <li key={item.id} className="grid-item">
              <m.div
                className="portfolio-overlay overflow-hidden"
                initial={{ opacity: 0 }}
                whileInView={!loading && { opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, ease: 'easeOut' }}
              >
                <div className="portfolio-image relative" style={style}>
                  {item?.localFile && (
                    <img
                      className="w-full"
                      src={item.localFile.publicURL}
                      height={447}
                      width={550}
                      alt="portfolio-overlay"
                    />
                  )}
                  <div className="portfolio-hover absolute font-serif justify-end flex flex-col py-[50px] text-center xl:py-[30px]" />
                </div>
              </m.div>
            </li>
          );
        })}
      </ul>
      {/* Grid End */}
    </div>
  );
};

const PortfolioOverlayTwoColPage = (props: any) => {
  return (
    <div style={props.style}>
      <section className="relative pt-0 py-[50px]">
        <Container>
          <Row>
            <Col xs={12} className="sm:!px-0">
              <PortfolioOverlay
                grid="grid grid-2col xl-grid-2col lg-grid-2col md-grid-2col sm-grid-2col xs-grid-1col gutter-large"
                data={props.data}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export { PortfolioOverlayTwoColPage };

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, { useEffect, useState, useContext, memo } from 'react';
import { useScroll } from 'framer-motion';
import { useLocation } from '@reach/router';
import { Container, Navbar } from 'react-bootstrap';
import { Link } from 'gatsby';
import GlobalContext from '../../context/Context';

/* Header Component Start */

export const Header = memo((props: any) => {
  // Add Global Header Data
  const { setHeaderHeight } = useContext(GlobalContext);
  const { scrollY } = useScroll();
  const [scrollPos, setScrollPos] = useState({
    y: 0,
    prevY: -1,
    directionDown: true,
  });
  const location = useLocation();

  useEffect(() => {
    const headerEl = document.querySelector('header');

    // Calculate header height
    function setTopSpace() {
      const windowWidth = window.innerWidth;
      let headerheight =
        props.topSpace.desktop && props.topSpace.desktop === true
          ? headerEl!.offsetHeight
          : 0;

      if (windowWidth <= 1199 && props.topSpace.lg) {
        headerheight = props.topSpace.lg === true ? headerEl!.offsetHeight : 0;
      }

      if (windowWidth <= 991 && props.topSpace.md) {
        headerheight = props.topSpace.md === true ? headerEl!.offsetHeight : 0;
      }

      if (windowWidth <= 767 && props.topSpace.sm) {
        headerheight = props.topSpace.sm === true ? headerEl!.offsetHeight : 0;
      }

      if (windowWidth <= 575 && props.topSpace.xs) {
        headerheight = props.topSpace.xs === true ? headerEl!.offsetHeight : 0;
      }

      setHeaderHeight(headerheight);
    }

    setTopSpace();

    window.addEventListener('load', setTopSpace);
    window.addEventListener('resize', setTopSpace);

    if (document.body.classList.contains('mobile-menu')) {
      document.body.classList.remove('navbar-collapse-show');
      document.body.classList.remove('menu-modern');
      document.body.classList.remove('menu-full');
      document.body.style.removeProperty('overflow');
    }
  }, [location]);

  useEffect(() => {
    let lastScrollTop = 0;
    scrollY.onChange(pos => {
      if (pos > lastScrollTop) {
        setScrollPos({
          ...scrollPos,
          y: pos,
          prevY: pos - 1,
          directionDown: true,
        });
      } else {
        setScrollPos({
          ...scrollPos,
          y: pos,
          prevY: pos - 1,
          directionDown: false,
        });
      }

      if (pos === 0) {
        setScrollPos({ ...scrollPos, directionDown: true });
      }
      lastScrollTop = pos;
    });
  }, []);

  return (
    <header
      className={`${props.className ? props.className : ''}${
        scrollPos.y > 5 ? ' sticky-header' : ''
      }${scrollPos.directionDown === false ? ' header-appear' : ''}${
        props.type ? ` ${props.type}` : ''
      }`}
    >
      {props.children}
    </header>
  );
});

/* Header Component End */

/* Headernav Component Start */
export const HeaderNav = (props: any) => {
  const handleMenuToggle = () => {
    const header = document.querySelector('header');
    const menu = header!.querySelector('.navbar-nav');
    const menu_item = menu!.querySelectorAll('.nav-item');

    if (!document.body.classList.contains('navbar-collapse-show')) {
      document.body.classList.add('navbar-collapse-show');
    } else {
      document.body.classList.remove('navbar-collapse-show');
    }

    menu_item.forEach(function (item) {
      if (item.classList.contains('open')) {
        setTimeout(() => {
          item.classList.remove('open');
        }, 200);
      }
    });
  };

  return (
    <Navbar
      collapseOnSelect
      id="headerbar"
      expand={props.expand}
      bg={props.bg ? props.bg : 'transparent'}
      variant={props.theme}
      className={`${props.menu && `menu-${props.menu}`}${
        props.className ? ` ${props.className}` : ''
      }${props.bg || props.bg === 'transparent' ? '' : ' header-transparent'}`}
      onToggle={handleMenuToggle}
      expanded={props.expanded}
    >
      <Container
        fluid={props.fluid}
        className={props.containerClass ? props.containerClass : ''}
      >
        {props.children}
      </Container>
    </Navbar>
  );
};
/* Headernav Component End */

/* Menu Component Start */
export const Menu = memo((props: any) => {
  const [isMenuActive, setMenuActive] = useState(null);
  const [isHover, setIsHover] = useState(false);
  const handleMenuClick = (_e: any, index: any) =>
    setMenuActive(index !== isMenuActive ? index : null);
  // set Active Menu
  const location = useLocation();
  useEffect(() => {
    const header = document.querySelector('header');
    const links = header!.querySelectorAll('.nav-link');

    const activeLink = Array.from(links).find(
      link => link.getAttribute('href') === location.pathname
    );
    if (activeLink) {
      import('../../Functions/Utilities').then(module => {
        const filtered_dropdown = module
          .getParents(activeLink)
          .filter(item => item.classList.contains('simple-dropdown'));
        const filtered_nav_item = module
          .getParents(activeLink)
          .filter(item => item.classList.contains('nav-item'));
        filtered_dropdown.forEach(item => item.classList.add('active'));
        filtered_nav_item.forEach(item => item.classList.add('active'));
      });
    }
  }, [location, isHover]);

  useEffect(() => {
    const navItems = document
      .querySelector('header')
      .querySelectorAll('.navbar-nav > .nav-item');
    navItems.forEach(nav =>
      nav.addEventListener('mouseover', () => setIsHover(true))
    );
  }, []);

  return (
    <div
      className={`${props.mobileMenu ? `mobile-menu-${props.mobileMenu}` : ''}${
        props.className ? ` ${props.className}` : ''
      }`}
    >
      <ul className="navbar-nav">
        {props.menu.map((item: any, i: any) => {
          return (
            <li
              className={`nav-item ${item.hasSubmenu ? ` dropdown` : ''}${
                isMenuActive === i ? ' open' : ''
              }`}
              key={item.id}
            >
              {item.link ? (
                <Link
                  className="nav-link hover:bg-sky-100"
                  to={item.link}
                  onClick={() => props.setExpanded(false)}
                >
                  {item.title}
                </Link>
              ) : (
                <span
                  className="nav-link hover:bg-sky-100"
                  onClick={() => props.setExpanded(false)}
                >
                  {item.title}
                </span>
              )}
              <i
                className="fa fa-angle-down"
                onClick={e => handleMenuClick(e, i)}
              />
              {item.hasSubmenu && (
                <ul className="simple-dropdown-menu">
                  {item.subMenu.map((subItem: any) => {
                    return (
                      <li
                        key={subItem.id}
                        className="simple-dropdown !pr-[35px] hover:bg-sky-100"
                      >
                        {item.link ? (
                          <Link
                            className="nav-link"
                            to={subItem.link}
                            onClick={() => props.setExpanded(false)}
                          >
                            {subItem.title}
                            {subItem.dropdown && (
                              <i className="fas fa-angle-right" />
                            )}
                          </Link>
                        ) : (
                          <span
                            className="nav-link"
                            onClick={() => props.setExpanded(false)}
                          >
                            {subItem.title}
                            {subItem.dropdown && (
                              <i className="fas fa-angle-right" />
                            )}
                          </span>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
});
/* Menu Component End */

exports.components = {
  "component---src-domain-about-us-tsx": () => import("./../../../src/domain/about-us.tsx" /* webpackChunkName: "component---src-domain-about-us-tsx" */),
  "component---src-domain-contact-us-tsx": () => import("./../../../src/domain/contact-us.tsx" /* webpackChunkName: "component---src-domain-contact-us-tsx" */),
  "component---src-domain-feedback-tsx": () => import("./../../../src/domain/feedback.tsx" /* webpackChunkName: "component---src-domain-feedback-tsx" */),
  "component---src-domain-knowledge-tsx": () => import("./../../../src/domain/knowledge.tsx" /* webpackChunkName: "component---src-domain-knowledge-tsx" */),
  "component---src-domain-news-tsx": () => import("./../../../src/domain/news.tsx" /* webpackChunkName: "component---src-domain-news-tsx" */),
  "component---src-domain-products-tsx": () => import("./../../../src/domain/products.tsx" /* webpackChunkName: "component---src-domain-products-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-knowledgedetails-tsx": () => import("./../../../src/templates/knowledgedetails.tsx" /* webpackChunkName: "component---src-templates-knowledgedetails-tsx" */),
  "component---src-templates-newsdetails-tsx": () => import("./../../../src/templates/newsdetails.tsx" /* webpackChunkName: "component---src-templates-newsdetails-tsx" */),
  "component---src-templates-productdetails-tsx": () => import("./../../../src/templates/productdetails.tsx" /* webpackChunkName: "component---src-templates-productdetails-tsx" */)
}


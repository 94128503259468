/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import ReactMarkdown from 'react-markdown';

// Libraries
import { Row } from 'react-bootstrap';
import { m } from 'framer-motion';

const Services = memo((props: any) => {
  return (
    <Row className={props.grid}>
      {props.data.map((item: any, i: any) => {
        return (
          <m.div
            key={item.id}
            className={`col px-[15px]${
              props.className ? ` ${props.className}` : ''
            }`}
            {...{
              ...props.animation,
              transition: { delay: i * props.animationDelay },
            }}
          >
            <div className={props.theme}>
              <div className="img-wrapper">
                <span className="text-xmd">
                  {i + 1 >= 10 ? '' : '0'}
                  {i + 1}
                </span>
              </div>
              <div className="service-style">
                {item.title && (
                  <span className="title font-medium text-darkgray block font-serif mb-[10px]">
                    {item.title}
                  </span>
                )}
                {item.description && (
                  <ReactMarkdown>
                    {item.description.data.description}
                  </ReactMarkdown>
                )}
              </div>
            </div>
          </m.div>
        );
      })}
    </Row>
  );
});

export { Services };

import React, { useState, useEffect, useRef, memo } from 'react';
import moment from 'moment';
import { Buttons } from 'components';

// Libraries
import { Link } from 'gatsby';
import { m } from 'framer-motion';

const BlogWidget = memo((props: any) => {
  const blogWrapper = useRef();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    import('../../Functions/Utilities').then(module => {
      const grid = module.initializeIsotop(blogWrapper.current);
      grid.on('arrangeComplete', () => setLoading(false));
    });
  }, []);

  return (
    <div className="grid-wrapper">
      {/* Grid Start */}
      <ul
        ref={blogWrapper}
        className={`blog-widget grid-container ${
          props.grid ? ` ${props.grid}` : ''
        }${loading ? ' loading' : ''}${
          props.filter === false ? '' : ' mt-10 sm:mt-8'
        }`}
      >
        <li className="grid-sizer" />
        {props.data.map((item: any) => {
          return (
            <li
              key={item.node.title}
              className={`grid-item${
                item.double_col ? ' grid-item-double' : ''
              }`}
            >
              <m.div
                className="blog-widget-content bg-white flex p-[15px] rounded-[4px]"
                initial={{ opacity: 0 }}
                whileInView={!loading && { opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, ease: 'easeOut' }}
              >
                {item.node.image ? (
                  <figure className="shrink-0 h-auto w-[140px] mb-0 xs:w-[100px]">
                    <Link
                      className="w-full h-full"
                      aria-label="link"
                      to={`/${props.parentSlug}/${[item.node.slug]}`}
                    >
                      <img
                        src={item?.node?.image?.localFile?.publicURL}
                        alt={item.node.title}
                        className="knowledgedetail-section-cardimage max-h-[100px] h-full w-full"
                      />
                    </Link>
                  </figure>
                ) : (
                  <figure className="shrink-0 h-auto w-[140px] mb-0 xs:w-[100px]">
                    <Link
                      className="w-full h-hull"
                      aria-label="link"
                      to={`/${props.parentSlug}/${[item.node.slug]}`}
                    >
                      <img
                        src="/img/defaultNews.jpg"
                        alt={item.node.title}
                        className="knowledgedetail-section-cardimage max-h-[100px] h-full w-full"
                      />
                    </Link>
                  </figure>
                )}
                <div className="leading-normal pl-[15px] relative top-[-1px] grow">
                  {item.node.title && (
                    <Link
                      aria-label="link"
                      to={`/${props.parentSlug}/${[item.node.slug]}`}
                      className="mb-0 leading-[22px] font-medium text-darkgray font-serif block hover:!text-sky-400"
                    >
                      {item.node.title}
                    </Link>
                  )}
                  {item.node.date && (
                    <span className="mb-[5px] text-xs font-serif block py-2">
                      {moment(item.node.date).format('MMM D, YYYY')}
                    </span>
                  )}
                  <div className="inline-block knowledgedetail-section">
                    <Buttons
                      href={`/${props.parentSlug}/${[item.node.slug]}`}
                      className="btn-fill !px-[14px] text-xs tracking-[1px] font-serif font-medium rounded-none py-[12px] uppercase md:mb-[15px] sm:mb-0"
                      themeColor="#42A7C3"
                      size="xs"
                      color="#fff"
                      title="Read more"
                    />
                  </div>
                </div>
              </m.div>
            </li>
          );
        })}
      </ul>
      {/* Grid Start */}
    </div>
  );
});

export { BlogWidget };

import React, { useState, useEffect, useRef, memo } from 'react';

// Libraries
import { Link } from 'gatsby';
import { m } from 'framer-motion';
import { Buttons } from 'components';

// Data

// Filter the blog data category wise

const BlogGrid = memo((props: any) => {
  const blogWrapper = useRef();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    import('../../Functions/Utilities').then(module => {
      const grid = module.initializeIsotop(blogWrapper.current);
      grid.on('arrangeComplete', () => setLoading(false));
    });
  }, []);

  const style = {
    '--overlay-color':
      typeof props.overlay === 'object'
        ? `linear-gradient(to right top, ${props.overlay.map(
            (item, i) => item
          )})`
        : props.overlay,
  };

  return (
    <div className="grid-wrapper">
      {/* Grid Start */}
      <ul
        ref={blogWrapper}
        className={`grid-container${props.grid ? ` ${props.grid}` : ''}${
          loading ? ' loading' : ''
        }${props.filter === false ? '' : ' mt-6 md:mt-[2.5rem] sm:mt-4'}`}
      >
        <li className="grid-sizer" />
        {props.data.map((item: any) => {
          const { title, slug, id, image, date } = item.node;
          return (
            <li
              className={`grid-item${
                item.double_col ? ' grid-item-double' : ''
              } `}
              key={id}
            >
              <m.div
                className="blog-grid rounded-[5px] overflow-hidden"
                initial={{ opacity: 0 }}
                whileInView={!loading && { opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.9, ease: 'easeOut' }}
              >
                <div
                  className="blog-post relative overflow-hidden"
                  style={style}
                >
                  {image?.length > 0 && image[0]?.localFile?.publicURL ? (
                    <Link aria-label="link" to={`/${props.parentSlug}/${slug}`}>
                      <img
                        height={245}
                        width={350}
                        src={image[0].localFile.publicURL}
                        alt="blog"
                      />
                    </Link>
                  ) : (
                    <Link aria-label="link" to={`/${props.parentSlug}/${slug}`}>
                      <img
                        height={245}
                        width={350}
                        src="/img/defaultNews.jpg"
                        alt="blog"
                      />
                    </Link>
                  )}
                </div>
                <div className="px-12 py-10 bg-white sm:px-8 xs:px-12">
                  {date && (
                    <span className="mb-[10px] text-sm font-serif inline-block">
                      {date}
                    </span>
                  )}
                  {(slug || title) && (
                    <Link
                      aria-label="link"
                      to={`/${props.parentSlug}/${slug}`}
                      className="font-serif mb-[15px] text-xmd block font-medium text-darkgray hover:text-basecolor"
                    >
                      {title}
                    </Link>
                  )}
                  <div className="flex items-center">
                    <Buttons
                      ariaLabel="Read more"
                      to={`/${props.parentSlug}/${slug}`}
                      className="btn-fill mt-[20px] font-medium font-serif tracking-[1px] rounded-none uppercase"
                      themeColor="#42A7C3"
                      color="#ffffff"
                      size="xs"
                      title="Read More"
                    />
                  </div>
                </div>
              </m.div>
            </li>
          );
        })}
      </ul>
      {/* Grid End */}
    </div>
  );
});

export { BlogGrid };

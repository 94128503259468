/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

// Libraries
import { Row } from 'react-bootstrap';
import { m } from 'framer-motion';

const IconWithText = memo((props: any) => {
  return (
    <Row className={`${props.grid} md:justify-center`}>
      {props.data.map((item: any, i: number) => {
        return (
          <m.div
            key={item.id}
            className={`col${props.theme ? ` ${props.theme}` : ''}${
              props.className ? ` ${props.className}` : ''
            }`}
            {...{
              ...props.animation,
              transition: {
                delay: i * props.animationDelay,
                ease: props.animationTransition,
                duration: props.animationDuration,
              },
            }}
          >
            <div className="rounded-md w-full">
              {item?.icon && <i className={`${item.icon} !text-sky-500`} />}

              <div className="feature-box-content">
                {item.title && (
                  <span className="font-medium title font-serif text-[#000]">
                    {item.title}
                  </span>
                )}
                {item.listTitles && (
                  <ul className="list-disc list-inside	">
                    {item.listTitles.map((listTitle: any) => (
                      <li key={listTitle.id}>{listTitle.title}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </m.div>
        );
      })}
    </Row>
  );
});

export { IconWithText };

/* eslint-disable react/destructuring-assignment */
import React, { memo, useEffect, useContext } from 'react';

// Libraries
import { Link } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';

// Components
import { SocialIcons } from 'components';
import GlobalContext from '../../context/Context';

// Data
import { HeaderAndFooterQuery } from '../../query';

export const FooterLayout = (props: any) => {
  // Add Global Data
  const { setFooterHeight } = useContext(GlobalContext);

  useEffect(() => {
    // Calculate Footer Height

    function setTopSpace() {
      const footerHeight = 0;

      setFooterHeight(footerHeight - 1);
    }

    setTimeout(setTopSpace, 1000);
    window.addEventListener('resize', function () {
      setTimeout(setTopSpace, 1000);
    });
  }, []);

  return (
    <footer
      className={`${props.theme}${
        props.className ? ` ${props.className}` : ''
      }`}
      style={props.style}
    >
      {props.children}
    </footer>
  );
};

const FooterMenu = ({ data, titleClass, className, ...props }: any) => {
  return (
    <>
      {data.map((item: any) => {
        return (
          <Col
            key={item.id}
            {...props}
            className={`footer-menu${className ? ` ${className}` : ''}`}
          >
            {item.title && (
              <span
                className={`mb-[20px] block font-medium font-serif xs:!mb-[10px]${
                  titleClass ? ` ${titleClass}` : ''
                }`}
              >
                {item.title}
              </span>
            )}
            <ul>
              {item.footerNavigation.map(item => {
                return (
                  (item.link || item.title) && (
                    <li key={item.id} className="mb-[7px] last:mb-0">
                      <Link aria-label="footer menu link" to={item.link}>
                        {item.title}
                      </Link>
                    </li>
                  )
                );
              })}
            </ul>
          </Col>
        );
      })}
    </>
  );
};
const Footer = memo((props: any) => {
  const footerdata = HeaderAndFooterQuery();
  const {
    footerMenu,
    copyrightMessage,
    faxNumber,
    phoneNumber,
    email,
    telephoneNumber,
    address,
    SocialMediaLink,
    footerLogo,
    whatsUpNumber,
  } = footerdata.strapiGlobal;
  return (
    <FooterLayout
      theme={props.theme}
      className={`${props.className ? ` ${props.className}` : ''}`}
    >
      <div className="py-[5%] lg:py-[95px] md:py-[50px]">
        <Container>
          <Row>
            <FooterMenu
              data={footerMenu}
              lg={4}
              sm={6}
              className="xl:px-[15px] md:mb-[40px] xs:mb-[25px]"
              titleClass="capitalize"
            />
            <Col lg={4} sm={6} className="xs:mb-[25px]">
              <span className="mb-[20px] font-serif block font-medium text-themecolor xs:mb-[10px]">
                Get in touch
              </span>
              <p className="w-[85%] mb-[15px]">{address.data.address}</p>
              <div>
                <i className="feather-phone-call text-sm mr-[10px] text-themecolor" />
                {telephoneNumber}
              </div>
              <div>
                <i className="feather-smartphone text-sm mr-[10px] text-themecolor" />
                {phoneNumber}
              </div>
              <div>
                <i className="feather-printer text-sm mr-[10px] text-themecolor" />
                {faxNumber}
              </div>
              <div>
                <i className="feather-mail text-sm mr-[10px] text-themecolor" />
                <a aria-label="mail" href="mailTo:info@yourdomain.com">
                  {email}
                </a>
              </div>
              <div>
                <i className="fab fa-whatsapp	text-sm mr-[10px] text-themecolor" />
                <a
                  aria-label="Chat on WhatsApp"
                  href={`https://wa.me/${whatsUpNumber}`}
                >
                  {whatsUpNumber}
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="py-[40px] border-t border-[#ffffff1a]">
        <Container>
          <Row>
            <Col md={3} className="sm:mb-[20px]">
              <Link
                aria-label="homepage"
                to="/"
                className="sm:flex sm:justify-center"
              >
                <img
                  alt="logo"
                  src={footerLogo.localFile.publicURL}
                  width={180}
                  height={36}
                />
              </Link>
            </Col>
            <Col
              md={6}
              className="flex justify-center items-center text-center sm:mb-[20px]"
            >
              <p className="mb-0">
                {new Date().getFullYear()} {copyrightMessage}
              </p>
            </Col>
            <Col md={3} className="text-right sm:text-center">
              <SocialIcons
                size="xs"
                theme="social-icon-style-01"
                className="justify-end sm:justify-center"
                iconColor={props.theme === 'dark' ? 'light' : 'dark'}
                data={SocialMediaLink}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </FooterLayout>
  );
});

export { Footer };

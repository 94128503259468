/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';

// Libraries
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'gatsby';
import { Autoplay, Pagination, Navigation } from 'swiper';
import { m } from 'framer-motion';
import { Buttons } from 'components';

const Slider = (props: any) => {
  const swiperRef = useRef(null);
  const [hydrated, setHydrated] = React.useState(false);
  React.useEffect(() => {
    setHydrated(true);
  }, []);
  if (!hydrated) {
    return null;
  }
  return (
    <div className={`relative${props.className ? ` ${props.className}` : ''}`}>
      <div
        onClick={() => swiperRef.current.swiper.slidePrev()}
        className="swiper-button-prev -left-[70px] lg:left-[-12px] landscape:md:left-[-7px] xs:left-0"
      />
      <Swiper
        className="h-full p-[15px]"
        ref={swiperRef}
        {...{
          slidesPerView: 1,
          spaceBetween: 30,
          observer: true,
          observeParents: true,
          keyboard: { enabled: true, onlyInViewport: true },
          loop: true,
          breakpoints: {
            1200: { slidesPerView: 4 },
            992: { slidesPerView: 3 },
            768: { slidesPerView: 2 },
          },
        }}
        modules={[Pagination, Autoplay, Navigation]}
      >
        {props?.data.map((item: any) => {
          const { id, image, title, slug, productCategory } = item;
          return (
            <SwiperSlide key={id} className="h-auto">
              <m.div
                className="swiper-slide shadow-[0_0_15px_rgba(0,0,0,0.08)] flex	 flex-col justify-between	"
                {...{
                  transition: { delay: 0.2 },
                }}
              >
                <Link aria-label="link" to={`/${productCategory.slug}/${slug}`}>
                  <img
                    width={263}
                    height={216}
                    src={image[0].localFile.publicURL}
                    className="w-full"
                    alt="popular packages"
                  />
                </Link>
                <div className="relative bg-white">
                  <Link
                    aria-label="link"
                    to={`/${productCategory.slug}/${slug}`}
                    className="font-serif font-medium block mb-[30px] leading-[24px] text-darkgray hover:text-neonorange px-8 md:px-8 mt-[15px]"
                  >
                    {title}
                  </Link>
                </div>
                <Buttons
                  href={`/${productCategory.slug}/${slug}`}
                  className="btn-fill text-xs tracking-[1px] font-serif font-medium rounded-none py-[12px] uppercase mb-[20px]  w-[80%] md:w-[90%] !mx-[10%] md:!mx-[5%]"
                  themeColor="#42A7C3"
                  size="sm"
                  color="#fff"
                  title="View Product"
                />
              </m.div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div
        onClick={() => swiperRef.current.swiper.slideNext()}
        className="swiper-button-next -right-[70px] lg:right-[-12px] landscape:md:right-[-7px] xs:right-0"
      >
        {' '}
      </div>
    </div>
  );
};

export { Slider };

import { Link } from 'gatsby';
import React from 'react';

// Libraries
import { Col, Container, Row } from 'react-bootstrap';

const Filter = ({ filterData, filterVal, productSlug }: any) => {
  return (
    <Container className="mt-12 sm:mt-8">
      <Row
        className={`${'justify-center'} items-center md:justify-center md:items-center md:text-center md:flex-co`}
      >
        <Col xs="auto">
          {filterData && (
            <ul className="filter-menu items-center justify-center flex flex-wrap uppercase">
              <li
                key="product"
                className={`mx-[5px] mb-[5px] !px-[15px] py-[10px] hover:bg-yellow-400 ${
                  filterVal === productSlug
                    ? '  bg-yellow-400 active'
                    : 'bg-gray-100'
                }`}
              >
                {filterVal === productSlug ? (
                  <span>All</span>
                ) : (
                  <Link to={`/${productSlug}`}>All</Link>
                )}
              </li>
              {filterData.map((item: any) => {
                const { id, title, slug } = item.node;
                return (
                  <li
                    key={id}
                    className={`mx-[5px]  mb-[5px] !px-[15px] py-[10px] hover:bg-yellow-400 ${
                      filterVal === slug
                        ? ' bg-yellow-400 active'
                        : 'bg-gray-100'
                    }`}
                  >
                    {filterVal === slug ? (
                      <span className="font-bold">{title}</span>
                    ) : (
                      <Link to={`/${slug}`}>{title}</Link>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export { Filter };

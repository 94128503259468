/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

const Pagination = ({ nPages, currentPage, setCurrentPage }: any) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
  const goToNextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const goToPrevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  return (
    <ul className="pagination pagination-style-01 font-sans font-medium flex items-center justify-center mt-[20px]">
      <li className="page-item" onClick={goToPrevPage} onKeyDown={goToPrevPage}>
        <span className="page-link">
          <i className="feather-arrow-left text-lg" />
        </span>
      </li>
      {pageNumbers.map(pgNumber => (
        <li
          className={`page-item ${currentPage === pgNumber ? 'active' : ''}`}
          onClick={() => setCurrentPage(pgNumber)}
          onKeyDown={() => setCurrentPage(pgNumber)}
          key={pgNumber}
        >
          <span className="page-link"> {pgNumber} </span>
        </li>
      ))}

      <li className="page-item" onClick={goToNextPage} onKeyDown={goToNextPage}>
        <span className="page-link">
          <i className="feather-arrow-right text-lg" />
        </span>
      </li>
    </ul>
  );
};

export { Pagination };

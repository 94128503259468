/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Link } from 'gatsby';
import { truncate } from 'utils';
import moment from 'moment';
import { Buttons } from 'components';

// Libraries
import { m } from 'framer-motion';
import ReactMarkdown from 'react-markdown';

const BlogSideImage = (props: any) => {
  return (
    <div>
      {props.data.map((item: any) => {
        const characterLimit = 120;
        return (
          <m.div
            key={item.node.title}
            className="blog-sideimage"
            {...props.animation}
          >
            {item.node.image ? (
              <div className="blog-post-image">
                <Link
                  aria-label="link"
                  to={`/${props.parentSlug}/${[item.node.slug]}`}
                >
                  <img
                    alt={item.node.title}
                    src={item.node?.image[0]?.localFile?.publicURL}
                  />
                </Link>
              </div>
            ) : (
              <div className="blog-post-image">
                <Link
                  aria-label="link"
                  to={`/${props.parentSlug}/${[item.node.slug]}`}
                >
                  <img alt={item.node.title} src="/img/defaultNews.jpg" />
                </Link>
              </div>
            )}
            <div className="post-details">
              {item.node.title && (
                <Link
                  aria-label="link"
                  to={`/${props.parentSlug}/${[item.node.slug]}`}
                  className="blg-post-title hover:!text-sky-400"
                >
                  {item.node.title}
                </Link>
              )}
              {item.node.date && (
                <span>{moment(item.node.date).format('MMM D, YYYY')}</span>
              )}
              {item?.node?.description?.data?.description && (
                <ReactMarkdown className="mt-4 text-justify">
                  {truncate(
                    item.node.description.data.description,
                    characterLimit
                  )}
                </ReactMarkdown>
              )}

              <div className="inline-block">
                <Buttons
                  href={`/${props.parentSlug}/${[item.node.slug]}`}
                  className="btn-fill text-xs tracking-[1px] font-serif font-medium rounded-none py-[12px] mr-[30px] uppercase md:mb-[15px] sm:mb-0"
                  themeColor="#42A7C3"
                  size="xs"
                  color="#fff"
                  title="Read more"
                />
              </div>
            </div>
          </m.div>
        );
      })}
    </div>
  );
};

export { BlogSideImage };
